// Theme Styles
@import '../metronic/sass/style.react.scss';

// Default layouts themes
@import '../metronic/sass/themes/layout/header/base/light.scss';
@import '../metronic/sass/themes/layout/header/menu/light.scss';
@import '../metronic/sass/themes/layout/brand/dark.scss';
@import '../metronic/sass/themes/layout/aside/dark.scss';

.brand-dark {
  @import '../metronic/sass/themes/layout/brand/dark.scss';
}
.brand-light {
  @import '../metronic/sass/themes/layout/brand/light.scss';
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

body {
  height: 100%;
  background: #F8F9FB;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}
