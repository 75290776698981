.aside-minimize {
  #kt_aside {
    .brand .brand-logo {
      display: none;
    }
  }
}

#kt_aside_menu {
  overflow-y: scroll;
  height: calc(100vh - 65px);
  ul.menu-nav {
    padding-bottom: 50px;
  }
}

.menu-submenu  {
  display: flex !important;
  flex-grow: 1 !important;
  flex-direction: column !important;
}
