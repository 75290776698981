.admin-users {
  .table-left {
    font-weight: 700;
  }

  .empty-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }
}

.edit-permissions-table, .edit-users-table {
  tbody {
    display: block;
    min-height: 150px;
    max-height: 400px;
    overflow: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.user-agreements {
  margin-top: 20px;
  margin-bottom: 20px;

  .table th, .table td {
    vertical-align: middle;
  }

  tbody {
    tr:nth-child(2n) {
      td {
        border-bottom: 1px solid black;
      }
    }
  }
}
