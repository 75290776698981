.staticContentWrapper {
  display: flex;
  flex-direction: column;
}

.staticContentItem {
  display: flex;
  align-items: center;
  max-height: 36px;
  border: 2px solid #e5e5e5;
  margin: 4px 0;
  padding: 8px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6);
}
