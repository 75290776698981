.map-marker-wrapper {
  height: max-content;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-marker {
  font-size: 40px !important;
  transform: translate(-24px, -40px);
}
