@import '~react-datepicker/dist/react-datepicker.css';

.github-picker {
  margin-left: 15px !important;
  box-shadow: none !important;
  margin-top: 15px !important;
}

html {
  overflow: hidden;
}

.flex-root {
  .d-flex.flex-column-fluid {
    .container {
      max-width: 100% !important;

      .MuiContainer-root {
        padding-left: 14px;
        padding-right: 14px;
      }
    }
  }
}

.ui .disabled {
  opacity: 1 !important;
  color: #000000 !important;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  opacity: 1 !important;
}
